import { useWindowSize } from 'hooks/useWindowSize';
import Image, { ImageProps } from 'next/image';
import { useEffect, useState } from 'react';
import { isMobileWidth } from 'utils/helpers';
import style from './ImageBlock.module.scss';

type BlockImageProps = ImageProps & {};

export type ImageBlockType = {
  desktop: BlockImageProps;
  mobile?: BlockImageProps;
  alt: string;
  priority?: boolean;
};
//  docs: https://nextjs.org/docs/basic-features/image-optimization and https://nextjs.org/docs/api-reference/next/image

export const ImageBlock = ({
  desktop,
  mobile,
  priority,
  alt = '',
}: ImageBlockType): JSX.Element => {
  const [isDesktopLoaded, setIsDesktopLoaded] = useState<boolean>(false);
  const [isMobileLoaded, setIsMobileLoaded] = useState<boolean>(false);
  const [showMobile, setShowMobile] = useState<boolean>(false);

  const size = useWindowSize();

  const onDesktopLoadingComplete = () => {
    setIsDesktopLoaded(true);
  };
  const onMobileLoadingComplete = () => {
    setIsMobileLoaded(true);
  };
  const mobileLoadState = isMobileLoaded ? style.imageLoaded : null;
  const desktopLoadState = isDesktopLoaded ? style.imageLoaded : null;

  const canSwap = mobile && desktop;
  const desktopClass = canSwap ? style.desktopImageCanSwap : style.desktopImage;

  useEffect(() => {
    const isMobile = isMobileWidth(size.width);
    setShowMobile(isMobile);
  }, [size.width]);

  return (
    <>
      {showMobile && mobile ? (
        <Image
          className={`${style.mobileImage} ${mobileLoadState} block-image-mobile`}
          {...mobile}
          sizes={mobile.sizes || '100vw'}
          alt={alt}
          onLoadingComplete={onMobileLoadingComplete}
          draggable={false}
        />
      ) : (
        <Image
          className={`${desktopClass} ${desktopLoadState} block-image-desktop`}
          {...desktop}
          sizes={desktop.sizes || '100vw'}
          draggable={false}
          alt={alt}
          priority={priority}
          onLoadingComplete={onDesktopLoadingComplete}
        />
      )}
    </>
  );
};
