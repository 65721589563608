import { createContext, useContext } from 'react';

export type AppContextType = {
  resetScreenSaverTimer: () => void;
};

export const AppContext = createContext<AppContextType>({
  resetScreenSaverTimer: () => {},
});

export const useAppContext = (): AppContextType => useContext(AppContext);
