import React, { useEffect, useRef, useState } from 'react';
import Modal from 'react-modal';

import style from './MainNav.module.scss';
import { Hamburger } from 'components/general/hamburger/Hamburger';
import { Menu } from './Menu';
import { useRouter } from 'next/router';
import { StickyScrollMenu } from 'components/general/stickyScrollMenu/StickyScrollMenu';
import { Logo } from 'components/logo/Logo';
import Link from 'next/link';
import { useGlobalContentContext } from 'context/GlobalContentContext';
import gsap from 'gsap/dist/gsap';
import { MenuList, MENU_ITEMS } from 'components/menuList/MenuList';

import { TextPlugin } from 'gsap/dist/TextPlugin';
import { isBrowser } from 'utils/helpers';
import LogoMobile from 'components/logo/logo-mobile.svg';

if (isBrowser()) {
  // Due to SSR we can only register when we are in the browser
  gsap.registerPlugin(TextPlugin);
}

Modal.setAppElement('#__next');

export type MainNavType = {};

export const MainNav = (props: MainNavType): JSX.Element => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [lastScroll, setLastScroll] = useState<number>(0);
  const router = useRouter();
  const sidebarRef = useRef<HTMLDivElement>(null);

  const { data } = useGlobalContentContext();
  useEffect(() => {
    const handleRouteChange = () => {
      setLastScroll(0);
      closeModal();
    };

    router.events.on('routeChangeStart', handleRouteChange);

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method:
    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, []);

  useEffect(() => {
    gsap.to('.js-sidebar-title', {
      text: {
        value: data.sidebar.title,
        delimiter: '',
        type: 'diff',
      },
    });
  }, [data.sidebar.title]);

  const openMenu = () => {
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
  };

  const toggleMenu = () => {
    setIsOpen(!modalIsOpen);
  };

  const onModalOpen = () => {
    setLastScroll(window.scrollY);
  };

  const onShowComplete = () => {
    document.body.classList.add('u-no-body-scroll');
  };
  const onModalClose = () => {
    document.body.classList.remove('u-no-body-scroll');
    window.scrollTo(0, lastScroll);
  };
  const customStyles = {
    content: {
      top: '0',
      left: '0',
      width: '100%',
      height: '100%',
      // right: '0',
      // bottom: '0',
      marginRight: '0',
      padding: 0,
      border: 0,
      borderRadius: 0,
      backgroundColor: 'rgba(0,0,0,0)',
      zIndex: 100,
    },
  };
  const mobileNavState = modalIsOpen ? style.mobileNavIsOpen : null;
  return (
    <>
      <StickyScrollMenu>
        <div className={style.stickyScrollContainer}>
          <div className={style.stickyScrollLogo}>
            <Logo large={true} />
          </div>
          <div className={style.stickyScrollToggleContainer}>
            <Hamburger onClick={toggleMenu} isOpen={modalIsOpen} />
          </div>
        </div>
      </StickyScrollMenu>
      <div className={style.gridContainer}>
        <div className={`${style.mobileNav} ${mobileNavState} js-mobile-nav`}>
          <div className={style.mobileNavLogo}>
            <Link href="/">
              <a>
                <LogoMobile />
              </a>
            </Link>
          </div>
          <div className={style.mobileNavHamburger}>
            <Hamburger onClick={toggleMenu} isOpen={modalIsOpen} />
          </div>
        </div>
        <div className={`${style.logoContainerEye} js-eye`}>
          <Link href="/">
            <a className={style.logoLink}>
              <Logo large={false} />
            </a>
          </Link>
        </div>

        <div className={style.sidebarContainer}>
          <div ref={sidebarRef} className={`${style.sidebar}  js-sidebar`}>
            {/* {data.sidebar.title} */}
            <div className={`${style.sidebarSpan} js-sidebar-title`} />
          </div>
        </div>
        <div className={style.menuContainer}>
          <MenuList className="js-top-menu" isAtBottom={false} />
        </div>

        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Menu overlay"
          shouldCloseOnOverlayClick={false}
          onAfterOpen={onModalOpen}
          onAfterClose={onModalClose}
          overlayClassName={style.overlay}
          closeTimeoutMS={750}
        >
          <Menu
            isOpen={modalIsOpen}
            items={MENU_ITEMS}
            closeTimeoutMS={750}
            addressHtml={''}
            blurbHtml={''}
            emailHtml={''}
            social={{ facebook: '', instagram: '', pinterest: '' }}
            onShowComplete={onShowComplete}
          />
        </Modal>
      </div>
    </>
  );
};
