import { useState, useEffect, useCallback } from 'react';
type ScrollDirection = { direction: 'up' | 'down' | undefined; scrollY: number };

export const useScrollDirection = (): ScrollDirection => {
  const [scroll, setScroll] = useState<ScrollDirection>({ direction: 'down', scrollY: 0 });
  let prevScrollY = 0;

  const onScroll = useCallback(() => {
    const newScrollY = window.scrollY;
    if (newScrollY > prevScrollY) {
      setScroll({ direction: 'down', scrollY: newScrollY });
    } else {
      setScroll({ direction: 'up', scrollY: newScrollY });
    }
    prevScrollY = scrollY;
  }, [scroll]);

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    onScroll();

    return () => window.removeEventListener('scroll', onScroll);
  }, []);
  return scroll;
};
