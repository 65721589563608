import { createContext, useContext } from 'react';

export type ThemeType = 'dark' | 'light';
export type ThemeContextType = {
  theme: ThemeType;
  setTheme: (theme: ThemeType) => void;
};

export const ThemeContext = createContext<ThemeContextType>({
  theme: 'dark',
  setTheme: () => {},
});

export const useThemeContext = (): ThemeContextType => useContext(ThemeContext);
