import { OfficeType } from 'pages/studio';
import { ProjectType } from 'pages/work/[slug]';
import { createContext, useContext } from 'react';

type AnalyticsType = {
  head: string,
  body: string,
}

export type SharedDataType = {
  projects: ProjectType[];
  intro: { html: string };
  sidebar: { title: string };
  offices: OfficeType[];
  socials: {};
  analytics: AnalyticsType;
};

export type GlobalContentContextType = {
  data: SharedDataType;
  setData: (sharedData: SharedDataType) => void;
};

export const GlobalContentContext = createContext<GlobalContentContextType>({
  data: {
    intro: { html: '' },
    sidebar: { title: '' },
    projects: [],
    offices: [],
    socials: { s: 's' },
    analytics: { head: '', body: '' },
  },
  setData: () => {},
});

export const useGlobalContentContext = (): GlobalContentContextType =>
  useContext(GlobalContentContext);
