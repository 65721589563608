import { Logo } from 'components/logo/Logo';
import { gsap } from 'gsap/dist/gsap';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import style from './IntroAnimation.module.scss';

export type IntroAnimationType = {
  onComplete: (triggerCarousel: boolean) => void;
  startScroll: (triggerCarousel: boolean) => void;
};

export const IntroAnimation = ({ onComplete, startScroll }: IntroAnimationType): JSX.Element => {
  // const { html } = useGlobalContentContext();
  const router = useRouter();
  useEffect(() => {
    const isHome = router.pathname === '/';

    if (!isHome) {
      onComplete(false);
      return;
    }

    const timeline = gsap.timeline();

    timeline.set(['.js-carousel-container-main', '.js-site-container', '.js-mobile-nav'], {
      autoAlpha: 0,
      filter: 'grayscale(1)',
    });
    timeline.set(['.js-intro-container'], { y: 0, autoAlpha: 1 });
    timeline.set(['.js-intro-logo'], { y: -10, autoAlpha: 0 });
    timeline.to(['.js-carousel-container-main'], {
      autoAlpha: 0.45,
    });

    timeline.to(['.js-intro-logo'], { y: 0, autoAlpha: 1 }, '>+=0.75');

    timeline.to(['.js-intro-logo'], {
      y: 10,
      autoAlpha: 0,
      display: 'none',
      delay: 0.55,
      onStart: () => {
        startScroll(true);
      },
    });

    timeline.to(
      ['.js-intro-container'],
      {
        autoAlpha: 0,
        display: 'none',
      },
      '>+=0.42',
    );
    timeline.to(
      ['.js-carousel-container-main', '.js-site-container', '.js-mobile-nav'],
      {
        filter: 'grayscale(0)',
        autoAlpha: 1,
        onComplete: () => {
          // @ts-ignore
          gsap.set(['.js-carousel-container-main', '.js-mobile-nav'], { filter: null });

          onComplete(true);
        },
      },
      '<-=0.2',
    );
  }, []);
  return (
    <>
      <div className={`${style.container} js-intro-container`}></div>
      {/* <div className={`${style.colourObscure} js-intro-solid-colour`}></div> */}
      <div className={`${style.logoContainer} js-intro-logo`}>
        <Logo large={false} />
      </div>
    </>
  );
};
