import React, { useEffect, useRef, useState } from 'react';
import { gsap } from 'gsap/dist/gsap';

import style from './Menu.module.scss';
import { useRouter } from 'next/router';
import Link from 'next/link';
import LogoSmall from '../logo/logo-eye.svg';

export type MenuType = {
  isOpen: Boolean;
  closeTimeoutMS: number;
  onShowComplete;
  addressHtml: string;
  emailHtml: string;
  blurbHtml: string;
  social: { pinterest: string; instagram: string; facebook: string };
  items: { href: string; label: string }[];
};

export const Menu = ({
  isOpen,
  closeTimeoutMS,
  onShowComplete,
  addressHtml,
  emailHtml,
  blurbHtml,
  social,
  items,
}: MenuType): JSX.Element => {
  const bg = useRef<HTMLDivElement>(null);
  const list = useRef<HTMLUListElement>(null);
  const footer = useRef<HTMLDivElement>(null);

  const openTimeline = gsap.timeline();
  const closeTimeline = gsap.timeline();

  const closingDuration = closeTimeoutMS / 1000;

  useEffect(() => {
    if (isOpen) {
      if (bg.current) {
        gsap.killTweensOf(bg.current);
        gsap.set(bg.current, { autoAlpha: 0, transformOrigin: '100%' });
        openTimeline.to(bg.current, {
          autoAlpha: 1,
          duration: 0.25,
          ease: 'power4.inOut',
          onComplete: () => {
            onShowComplete();
          },
        });
      }
      if (list.current) {
        gsap.utils.toArray(list.current.children).forEach((element: any, index) => {
          gsap.killTweensOf(element);
          gsap.set(element, { opacity: 0 });
          openTimeline.to(element, { opacity: 1, duration: 0.25 }, '<25%');
        });
      }
      if (footer.current) {
        gsap.utils.toArray(footer.current.children).forEach((element: any, index) => {
          gsap.killTweensOf(element);
          gsap.set(element, { opacity: 0 });
          openTimeline.to(element, { opacity: 1, duration: 0.15 }, '<');
        });
      }
    } else {
      // closing animation
      if (list.current) {
        gsap.utils.toArray(list.current.children).forEach((element: any, index) => {
          gsap.killTweensOf(element);
          closeTimeline.to(element, { opacity: 0, duration: 0.1 }, '<25%');
        });
      }
      if (footer.current) {
        gsap.utils.toArray(footer.current.children).forEach((element: any, index) => {
          gsap.killTweensOf(element);
          closeTimeline.to(element, { opacity: 0, duration: 0.1 }, '<%');
        });
      }
      if (bg.current) {
        gsap.killTweensOf(bg.current);
        closeTimeline.to(bg.current, { autoAlpha: 0, duration: 0.15, ease: 'power4.inOut' });
      }

      closeTimeline.duration(closingDuration);
      closeTimeline.play();
    }
  });

  const { asPath } = useRouter();

  return (
    <div className={style.menuContainer}>
      <div ref={bg} className={style.bg}></div>

      <ul ref={list} className={style.list}>
        {items.map((link) => {
          const itemState = link.href === asPath ? style.listItemLinkActive : null;
          return (
            <li className={`${style.listItem} `} key={link.label}>
              <Link href={link.href}>
                <a className={`${style.listItemLink} ${itemState}`}>{link.label}</a>
              </Link>
            </li>
          );
        })}
      </ul>
      <div ref={footer} className={style.eyeLogo}>
        <LogoSmall className={style.desktop} />
      </div>
    </div>
  );
};
