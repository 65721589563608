import { Logo } from 'components/logo/Logo';
import { gsap } from 'gsap/dist/gsap';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import style from './ScreenSaver.module.scss';
import SVGLogo from '../logo/logo-eye.svg';

export type ScreenSaverType = { active: boolean; show: boolean };

export const ScreenSaver = ({ show, active }: ScreenSaverType): JSX.Element | null => {
  const [shouldRender, setShouldRender] = useState<boolean>(false);

  useEffect(() => {
    if (!active) return;

    gsap.killTweensOf([
      '.js-screensaver-logo',
      '.js-screensaver-container',
      '.js-carousel-container-main',
      '.js-site-container',
    ]);

    const timeline = gsap.timeline();
    if (show) {
      // setShouldRender(true);
      timeline.set(['.js-screensaver-container'], { y: 0, autoAlpha: 0 });
      timeline.set(['.js-screensaver-logo'], { y: -10, autoAlpha: 0 });

      timeline.to(['.js-screensaver-container'], { y: 0, display: 'block', autoAlpha: 1 });
      timeline.to(
        [
          '.js-carousel-container-main',
          '.js-site-container',
          '.js-mobile-nav',
          '.js-mobile-sticky-menu',
        ],
        {
          filter: 'grayscale(1)',
          autoAlpha: 0.45,
        },
        '<',
      );
      timeline.to(['.js-screensaver-logo'], { y: 0, display: 'block', autoAlpha: 1 }, '>+=0.75');
    } else {
      const duration = 0.2;
      timeline.to(['.js-screensaver-logo'], {
        y: 10,
        autoAlpha: 0,
        display: 'none',

        duration,
      });

      timeline.to(['.js-screensaver-container'], {
        autoAlpha: 0,
        display: 'none',
        onComplete: () => {
          setShouldRender(false);
        },
        duration,
      });
      timeline.to(
        [
          '.js-carousel-container-main',
          '.js-site-container',
          '.js-mobile-nav',
          '.js-mobile-sticky-menu',
        ],
        {
          filter: 'grayscale(0)',
          autoAlpha: 1,
          onComplete: () => {
            gsap.set(
              [
                '.js-carousel-container-main',
                '.js-site-container',
                '.js-mobile-nav',
                '.js-mobile-sticky-menu',
              ],
              {
                // @ts-ignore
                filter: [],
              },
            );
          },
          duration,
        },
        '<',
      );
    }

    // return;
  }, [show, active]);

  // return null;

  if (!active) return null;
  return (
    <>
      <div className={`${style.container} js-screensaver-container`}></div>
      {/* <div className={`${style.positions}`}> */}
      <div className={`${style.logoContainer} js-screensaver-logo`}>
        <SVGLogo className={style.svg} />
        {/* </div> */}
      </div>
    </>
  );
};
