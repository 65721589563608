import { useScrollDirection } from 'hooks/useScroll';
import { useWindowSize } from 'hooks/useWindowSize';
import React, { useEffect } from 'react';
import { isBrowser } from 'utils/helpers';
import style from './StickyScrollMenu.module.scss';

export type StickyScrollMenuType = { children?: React.ReactNode };
export const StickyScrollMenu = ({ children }: StickyScrollMenuType): JSX.Element => {
  const scroll = useScrollDirection();
  const size = useWindowSize();
  let carousel;
  let carouselOnScreen;

  if (isBrowser()) {
    carousel = document.querySelector('.js-work-scrolltrigger');
    carouselOnScreen = carousel && carousel.getBoundingClientRect().y < size.height;
  }

  const containerState =
    scroll.direction === 'up' &&
    scroll.scrollY > 100 &&
    !carouselOnScreen &&
    !document.body.classList.contains('js-no-mobile-sticky-menu')
      ? style.containerIsVisible
      : null;

  return (
    <div className={`${style.container} ${containerState} js-mobile-sticky-menu`}>{children}</div>
  );
};
