import Link from 'next/link';
import { useRouter } from 'next/router';

import style from './PreviewBanner.module.scss';

export const PreviewBanner = (): JSX.Element => {
  const router = useRouter();
  
  return (
    <div className={style.container}>
      <div className={style.text}>Preview Mode</div>
      <Link href={`/api/clearPreview?path=${encodeURIComponent(router.pathname)}`}>
        <a className={style.exit}>Exit</a>
      </Link>
    </div>
  );
};
