import { Logo } from 'components/logo/Logo';
import { isHistoryEntryFromPopState } from '@moxy/react-page-swapper';
import { MainFooter } from 'components/mainFooter/MainFooter';
import {
  ProjectSelectorAnimationType,
  ProjectSelectorCarousel,
} from 'components/projectSelectorCarousel/ProjectSelectorCarousel';
import { gsap } from 'gsap/dist/gsap';
import { useWindowSize } from 'hooks/useWindowSize';
import { useRouter } from 'next/router';
import { ProjectType } from 'pages/work/[slug]';
import { useEffect, useRef, useCallback, useState } from 'react';
import style from './SelectorMenu.module.scss';

export type SelectorMenuType = {
  projects: ProjectType[];
  showIntroAnimation: boolean;
};

export const SelectorMenu = ({ projects, showIntroAnimation }: SelectorMenuType): JSX.Element => {
  const container = useRef<HTMLDivElement>(null);
  const [carouselAnimation, setCarouselAnimation] = useState<ProjectSelectorAnimationType>({
    id: 'reset-to-all',
    onComplete: () => {
      setScrollCheckEnabled(true);
    },
  });
  const [enabled, setEnabled] = useState<boolean>(false);
  const [scrollCheckEnabled, setScrollCheckEnabled] = useState<boolean>(true);
  const [DOMIsAvailable, setDOMIsAvailable] = useState<boolean>();

  const router = useRouter();

  const { height } = useWindowSize();
  const currentProject = projects.find((project) => {
    return project.slug === router.query.slug;
  });

  const setCarouselBasedOnScrollPos = useCallback((force: boolean = false) => {
    if (!enabled || !currentProject || !scrollCheckEnabled) {
      // console.log(enabled, currentProject, scrollCheckEnabled);
      return;
    }
    const scrollTriggerPosition =
      document.querySelector('.js-work-scrolltrigger')?.getBoundingClientRect().top || null;
    const workPageScrolledToEnd =
      scrollTriggerPosition !== null && scrollTriggerPosition < window.innerHeight;

    if (workPageScrolledToEnd) {
      container.current?.classList.remove('u-isFreeToScroll');
      // the workpage shows the carousel as a footer, show all slides
      if (force || carouselAnimation.id !== 'reset-to-all') {
        setCarouselAnimation({
          id: 'reset-to-all',
          force,
          onComplete: () => {
            // console.log("workPageScrolledToEnd")
            setScrollCheckEnabled(true);
          },
        });
        gsap.set(container.current, { y: 0 });
      }
    } else {
      // gsap.set(container.current, { y: -window.scrollY });
      container.current?.classList.add('u-isFreeToScroll');
      if (force) {
        setCarouselAnimation({
          id: 'reset-to-project',
          slug: currentProject.slug,
          force,
          onComplete: () => {
            // console.log("!workPageScrolledToEnd, force")
            setScrollCheckEnabled(true);
          },
        });
        return;
      }
      // we are on a project,no need to check anything
      if (carouselAnimation.id === 'select-project') return;

      // we sccrolled but not reached the end, so we scrolled up
      // reset the carousel to the current selected project url
      if (
        carouselAnimation.id !== 'reset-to-project' ||
        carouselAnimation.slug !== currentProject.slug
      ) {
        // BUG: only the very first time we land on project this doens't animate
        setCarouselAnimation({
          id: 'reset-to-project',
          slug: currentProject.slug,
          onComplete: () => {
            // console.log("carouselAnimation slug mismatch")
            setScrollCheckEnabled(true);
          },
        });
      } else {
        // console.log("empty else")
      }
    }
  }, [carouselAnimation.id, carouselAnimation.slug, currentProject, enabled, scrollCheckEnabled]);

  const onResize = useCallback(() => {
    if (currentProject) {
      setCarouselBasedOnScrollPos(true);
    }
  }, [currentProject, setCarouselBasedOnScrollPos]);

  useEffect(() => {
    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [onResize]);

  const onScroll = useCallback(() => setCarouselBasedOnScrollPos(false), [setCarouselBasedOnScrollPos]);

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [carouselAnimation, enabled, currentProject, container, onScroll]);

  useEffect(() => {
    const is404Page = router.pathname === '/404';
    const isProjectPage = router.pathname.includes('/work/');
    const showCarousel = isProjectPage || router.pathname === '/' || is404Page;

    setEnabled(showCarousel);
    gsap.to(container.current, { autoAlpha: showCarousel ? 1 : 0 });
    if (isProjectPage) {
      // if (is404Page) {
      //   setCarouselAnimation({ id: 'reset-to-all', showMenu: false });
      // } else {
      const currentRoute = router.pathname.replace('/work/', '');
      setCarouselAnimation({
        id: 'reset-to-project',
        slug: currentRoute,
        onComplete: () => {
          // console.log("isProjectPage")
          setScrollCheckEnabled(true);
        },
      });
      // }
    } else {
      setCarouselAnimation({
        id: 'reset-to-all',
        showMenu: router.pathname !== '/',
        skipAnimation: is404Page,
        onComplete: () => {
          // console.log("!isProjectPage")
          setScrollCheckEnabled(true);
        },
      });
    }
  // Intentionally don't include router.pathname here since it appears this
  // is only supposed to run once
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      const newPath = new URL(url, `${window.location.protocol}//${window.location.host}`).pathname;
      // remove bounce stopper class
      document.documentElement.classList.remove('u-stop-bounce');

      // setCarouselAnimation({ id: 'select-project', cameFromHome: false, slug: '' });
      // return;
      const currentRoute = router.pathname;
      // const currentIsHome = currentRoute === '/';
      const is404Page = newPath === '/404';
      const workIsNext = newPath.includes('/work/');
      const workIsCurrent = router.pathname.includes('/work/');
      const showCarousel = workIsNext || newPath === '/';
      // gsap.to(container.current, { autoAlpha: showCarousel ? 1 : 0 });
      setEnabled(showCarousel);
      // console.log("setScrollCheckEnabled false");
      setScrollCheckEnabled(false);

      // dont animate the carousel state because we will hide it from view
      if (!showCarousel) {
        gsap.to(container.current, { autoAlpha: 0 });
        // setCarouselAnimation({ id: 'reset-to-all', showMenu: true });
        // return;
      }
      const homeToProject = currentRoute === '/' && workIsNext;
      const projectToProject = workIsCurrent && workIsNext;
      const projectToHome = workIsCurrent && newPath === '/';

      const nextprojectSlug = newPath.replace('/work/', '');
      // console.log("nextprojectSlug", nextprojectSlug);

      gsap.killTweensOf(setCarouselAnimation);

      if (projectToProject) {
        // Safari sometimes doesn't return the correct DOM positions
        // we delay it for a deeplink - filthy...
        const hackDelayForSafari = DOMIsAvailable ? 0 : 0.5;
        if (!DOMIsAvailable) setDOMIsAvailable(true);
        const force = isHistoryEntryFromPopState();
        if (force) {
          window.scrollTo(0, 0);
        }
        gsap.delayedCall(hackDelayForSafari, () => {
          gsap.to(container.current, { autoAlpha: 1 });
          setCarouselAnimation({
            id: 'select-project',
            force,
            cameFromHome: false,
            slug: nextprojectSlug,
            onComplete: () => {
              // console.log("projectToProject")
              setScrollCheckEnabled(true);
            },
          });
        });
      } else if (homeToProject) {
        gsap.to(container.current, { autoAlpha: 1 });
        setCarouselAnimation({
          id: 'select-project',
          cameFromHome: true,
          slug: nextprojectSlug,
          onComplete: () => {
            // console.log("homeToProject")
            setScrollCheckEnabled(true);
          },
        });
      } else if (projectToHome) {
        gsap.to(container.current, { autoAlpha: 1 });
        gsap.to(container.current, { y: 0 });
        document.documentElement.classList.add('u-stop-bounce');
        setCarouselAnimation({
          cameFromDefaultPage: false,
          id: 'show-all',
          onComplete: () => {
            // console.log("projectToHome")
            setScrollCheckEnabled(true);
          },
        });
      } else {
        // comes from a random url,within the site.
        if (workIsNext) {
          gsap.to(container.current, { autoAlpha: 1 });
          setCarouselAnimation({
            id: 'reset-to-project',
            slug: nextprojectSlug,
            onComplete: () => {
              // console.log("workIsNext")
              setScrollCheckEnabled(true);
            },
          });
        } else if (newPath === '/') {
          // we are heading home, so animate instead of reset
          gsap.set(container.current, { y: 0, autoAlpha: 0 });
          gsap.to(container.current, { autoAlpha: 1, delay: 1 });
          // disable bouncy scroll on Safari for the homepage, add the class
          document.documentElement.classList.add('u-stop-bounce');
          setCarouselAnimation({
            cameFromDefaultPage: true,
            id: 'show-all',
            onComplete: () => {
              // console.log("newPath === '/'")
              setScrollCheckEnabled(true);
            },
          });
        } else {
          if (is404Page) {
            setCarouselAnimation({
              id: 'reset-to-all',
              showMenu: false,
              skipAnimation: is404Page,
              onComplete: () => {
                // console.log("is404Page")
                setScrollCheckEnabled(true);
              },
            });
          } else {
            // console.log("!is404Page (note: doesn't set scrollCheckEnabled)")
            gsap.delayedCall(2, setCarouselAnimation, [{ id: 'reset-to-all', showMenu: true }]);
          }
        }
      }
    };
    const handleRouteComplete = () => {
      // console.log("handleRouteComplete")
      setScrollCheckEnabled(true);
    };
    router.events.on('routeChangeStart', handleRouteChange);
    router.events.on('routeChangeComplete', handleRouteComplete);

    return () => {
      router.events.off('routeChangeComplete', handleRouteComplete);
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [router, DOMIsAvailable]);

  return (
    <div ref={container} className={` ${style.selectorContainer} `}>
      <div className={`${style.animationWrapper} js-carousel-container-main`}>
        <div className={`${style.logo} js-typo`}>
          <Logo large={true} />
        </div>
        <ProjectSelectorCarousel
          showIntroAnimation={showIntroAnimation}
          projects={projects}
          animation={carouselAnimation}
          onProjectSelect={() => {}}
        />
        <div className={`${style.menuList} js-carousel-footer`}>
          <MainFooter showMenu={true} showSocial={false} />
        </div>
      </div>
    </div>
  );
};
