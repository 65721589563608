import gsap from 'gsap/dist/gsap';
import { useWindowSize } from 'hooks/useWindowSize';
// import { useRouterHistory } from 'hooks/useRouterHistory';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { getCSSVar, hasScrolledToBottom, isMobileWidth, setCSSVar } from 'utils/helpers';
import styles from './PageTransition.module.scss';

export const PageTransition = ({
  node,
  animation = 'fade',
  style,
  in: inProp = false,
  onEntered,
  onExited,
  history,
}) => {
  const router = useRouter();
  const size = useWindowSize();
  const isMobileScreen = isMobileWidth(size.width);

  const [scrollTriggerInView, setScrollTriggerInView] = useState<boolean>(false);

  const timeMS = getCSSVar('--pageTransitionTime');
  const delayMS = getCSSVar('--pageTransitionTime');

  const test = () => {
    const trigger = document.querySelector('.js-work-scrolltrigger');
    const pos = trigger?.getBoundingClientRect().top || 0;

    return;
  };

  useEffect(() => {
    const onScroll = () => {
      const trigger = document.querySelector('.js-work-scrolltrigger');
      if (!trigger) {
        setScrollTriggerInView(false);
        return;
      }
      const pos = trigger.getBoundingClientRect().top;
      const scrolledCarouselIntoView = pos < window.innerHeight;
      if (scrolledCarouselIntoView !== scrollTriggerInView) {
        setScrollTriggerInView(scrolledCarouselIntoView);
      }
    };
    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);
  const onRouteEnter = () => {
    const delay = delayMS / 1000;

    if (isMobileScreen) {
      gsap.set('.js-site-container', { autoAlpha: 1 });

      gsap.to('.js-transitionOverlay', {
        duration: 1,
        delay: 1,
        autoAlpha: 0,
      });
    } else {
      const previousPage = history[history.length - 1];
      const goingToHome = router.pathname === '/';
      const previousIsWork = previousPage.includes('/work/');

      const goingFromProjectToProject = router.pathname.includes('/work/') && previousIsWork;

      if (previousPage === router.pathname) return;

      if (goingToHome) {
        //we don't want to see a fade
        if (previousIsWork) {
          // check if we have scrolled to bottom where carousel lives.

          //hasScrolledToBottom();
          // gsap.set('.js-site-container', { autoAlpha: 0 });

          if (!scrollTriggerInView) {
            gsap.to('.js-site-container', { autoAlpha: 0 });
          } else {
            gsap.set('.js-site-container', { autoAlpha: 0 });
          }
        } else {
          gsap.to('.js-site-container', { autoAlpha: 0 });
        }
        return;
      }
      gsap.set('.js-site-container', { autoAlpha: 1 });

      gsap.to('.js-transitionOverlay', {
        duration: delay * 0.6,
        delay: delay * 1.5,
        autoAlpha: 0,
      });
    }
  };
  const onRouteExit = () => {
    const time = getCSSVar('--pageTransitionTime') / 1000;
    const delay = delayMS / 1000;
    //  -2 because this is ONEXIT, so the route was already updated previously
    const previousPage = history[history.length - 2];
    const goingToHome = router.pathname === '/';
    const previousIsWork = previousPage.includes('/work/');

    const goingFromProjectToProject = router.pathname.includes('/work/') && previousIsWork;

    let duration = delay / 2;
    const carouselState = document.body.dataset.carousel;

    if (isMobileScreen) {
      gsap.set('.js-site-container', { autoAlpha: 1 });

      gsap.set('.js-transitionOverlay', {
        // duration,
        autoAlpha: 1,
        delay: 0,
      });
    } else {
      if (previousPage === router.pathname) {
        //still on same page, do we ignore?
        return;
      }

      if (goingToHome) {
        //we don't want to see a fade

        // comes from project page? check carousel state
        // comes from other page? fade
        if (previousIsWork) {
          const scrolledCarouselIntoView = hasScrolledToBottom();
          if (!scrolledCarouselIntoView) {
            gsap.to('.js-site-container', { autoAlpha: 0 });
          } else {
            gsap.set('.js-site-container', { autoAlpha: 0 });
          }
        } else {
          gsap.to('.js-site-container', { autoAlpha: 0 });
        }
        return;
      }

      if (previousPage === '/' && router.pathname.includes('/work/')) {
        //home > project = no fade
        return;
      }
      if (goingFromProjectToProject) {
        // project > project
        return;
      }

      gsap.set('.js-site-container', { autoAlpha: 1 });

      gsap.to('.js-transitionOverlay', {
        duration,
        autoAlpha: 1,
        delay: 0,
      });
    }
  };
  return (
    <CSSTransition
      in={inProp}
      // create a unique key so we have transitions each time the path changes (also sub id's)
      key={`${router.pathname}`}
      onExit={onRouteExit}
      onEntering={onRouteEnter}
      onExited={onExited}
      onEntered={onEntered}
      timeout={timeMS}
    >
      <div className={`js-site-container`}>{node}</div>
    </CSSTransition>
  );
};
