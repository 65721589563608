import style from './Logo.module.scss';
import LogoSmall from './logo-eye.svg';
import LogoLarge from './logo-large.svg';

export type LogoType = { large?: boolean };
export const Logo = ({ large }: LogoType): JSX.Element => {
  const containerState = large ? style.fitWidth : style.fitHeight;
  return (
    <div className={`${style.container} ${containerState}`}>
      {large ? <LogoLarge className={style.desktop} /> : <LogoSmall className={style.desktop} />}
    </div>
  );
};
