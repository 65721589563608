import { useRouterScroll } from '@moxy/next-router-scroll';
import { isHistoryEntryFromPopState } from '@moxy/react-page-swapper';
import PageSwapper from '@moxy/react-page-swapper';
import { PageTransition } from 'components/general/pageTransition/PageTransition';
import { useRouter } from 'next/router';

export const AppInner = ({ Component, pageProps, history }) => {
  const { updateScroll } = useRouterScroll();
  const mode = 'out-in';
  // const mode = 'simultaneous';

  const router = useRouter();

  return (
    <>
      {/* mode="out-in" */}
      <PageSwapper
        mode={mode}
        style={{ width: '100%' }}
        // updateScroll={updateScroll}
        updateScroll={(props) => {
          const fromPop = isHistoryEntryFromPopState();
          if (!fromPop) {
            window.scrollTo(0, 0);
          } else {
            updateScroll();
          }
        }}
        nodeKey={router.pathname}
        node={<Component {...pageProps} />}
      >
        {(props) => <PageTransition {...props} history={history} />}
      </PageSwapper>
    </>
  );
};
